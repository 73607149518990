import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import ReactPlaceholder from "react-placeholder";
import { TextRow } from "react-placeholder/lib/placeholders";
import "react-placeholder/lib/reactPlaceholder.css";
import uuid from "uid";
import { Thumbnail } from "../../../components";
import { humanizeField, isURL } from "../../../utils/utils";
import translate from "../texts";

import yaml from "js-yaml";

const fieldPlaceholder = (
  <>
    <div className="submission-data-field">
      <div>
        <TextRow color="#ddd" />
      </div>
      <div>
        <TextRow color="#ddd" />
      </div>
    </div>
    <div className="submission-data-field">
      <div>
        <TextRow color="#ddd" />
      </div>
      <div>
        <TextRow color="#ddd" />
      </div>
    </div>
    <div className="submission-data-field">
      <div>
        <TextRow color="#ddd" />
      </div>
      <div>
        <TextRow color="#ddd" />
      </div>
    </div>
  </>
);

const Data = ({
  submission,
  programFields,
  data,
  isFetching,
  openFileViewer,
  showEmptyFields,
}) => {
  const yamlRegex = /(\\r\\n|\\r|\\n|\\t|\r\n|\r|\n|\t)/;

  function preprocessYAMLString(encodedYAMLString) {
    const processedYAML = encodedYAMLString
      .replace(/\\n/g, "\n")
      .replace(/\\t/g, "\t")
      .replace(/\\r/g, "\r");
    return processedYAML;
  }

  function parseYAMLToJson(yamlString) {
    try {
      return yaml.load(yamlString);
    } catch (error) {
      console.error("Error parsing YAML:", error);
      return null;
    }
  }

  let documentCount = 0;

  function renderJsonData(data, depth = 0) {
    const indent = depth * 10;

    if (data === null || data === undefined) {
      return "N/A";
    }

    if (Array.isArray(data)) {
      return (
        <ul>
          {data.map((item, index) => (
            <li key={index} style={{ marginLeft: `${indent}px` }}>
              {renderJsonData(item, depth + 1)}
            </li>
          ))}
        </ul>
      );
    } else if (typeof data === "object") {
      return (
        <ul>
          {Object.entries(data).map(([key, value]) => (
            <li key={key} style={{ marginLeft: `${indent}px` }}>
              <strong>{humanizeField(key)}:</strong>{" "}
              {renderJsonData(value, depth + 1)}
            </li>
          ))}
        </ul>
      );
    } else if (isURL(data)) {
      const currentDocumentCount = ++documentCount;
      return (
        <a
          className="text-blue-700 underline"
          href={(data.startsWith("http") ? "" : "https://") + data}
          target="_blank"
          rel="noreferrer"
        >
          document {currentDocumentCount}
        </a>
      );
    } else {
      return data;
    }
  }

  return (
    <div className="submission-data">
      <ReactPlaceholder
        showLoadingAnimation
        customPlaceholder={fieldPlaceholder}
        ready={!isFetching}
      >
        <div className="submission-data-field">
          <div>
            <strong>{translate("createdAt")}</strong>
          </div>
          <div>{dayjs(submission.createdAt).format("YYYY-MM-DD h:mm a")}</div>
        </div>
        {programFields
          .filter((field) => data[field.id] !== undefined)
          .map((field) => {
            documentCount = 0;
            const isYaml = yamlRegex.test(data[field.id]);

            const yamlData = isYaml
              ? parseYAMLToJson(preprocessYAMLString(data[field.id]))
              : null;

            const emptyField =
              data[field.id] === "" ||
              data[field.id] === null ||
              data[field.id] === undefined;

            if (!emptyField || (emptyField && showEmptyFields)) {
              if (yamlData) {
                return (
                  <div
                    key={uuid()}
                    className={`submission-data-field ${
                      /[\u0600-\u06FF]/.test(field.name)
                        ? "direction-rtl"
                        : "direction-ltr"
                    }`}
                  >
                    <div className=" pb-1 mb-3 border-b-2">
                      <strong>{humanizeField(field.name)}</strong>
                    </div>
                    {renderJsonData(yamlData)}
                  </div>
                );
              }
              return (
                <div
                  key={uuid()}
                  className={`submission-data-field ${
                    /[\u0600-\u06FF]/.test(field.name)
                      ? "direction-rtl"
                      : "direction-ltr"
                  }`}
                >
                  <strong>{humanizeField(field.name)}</strong>
                  <br />
                  {isURL(data[field.id]) ? (
                    <Thumbnail
                      key={data[field.id]}
                      src={data[field.id]}
                      onClick={openFileViewer}
                    />
                  ) : (
                    <p>
                      {Array.isArray(data[field.id])
                        ? data[field.id].join(", ")
                        : data[field.id] || ""}
                    </p>
                  )}
                </div>
              );
            }
            return null;
          })}
        {submission?.externalStatus && (
          <div className="submission-data-field">
            <div>
              <strong>{translate("externalStatus")}</strong>
            </div>
            <div>{submission.externalStatus?.status}</div>
          </div>
        )}
      </ReactPlaceholder>
    </div>
  );
};

Data.propTypes = {
  submission: PropTypes.object.isRequired,
  programFields: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  openFileViewer: PropTypes.func.isRequired,
  showEmptyFields: PropTypes.bool.isRequired,
};

export default Data;
